@tailwind base;
@tailwind components;
@tailwind utilities;
.ant-btn {
    background-color: #3eb049;
    /*background-color: #70CB71;*/
}
.ant-select-clear .anticon-close-circle svg path {
    /*visibility: visible;*/
    fill: dimgrey;
}

/*.ant-select-clear:hover .anticon-close-circle svg path {*/
/*    fill: dimgrey;*/
/*}*/

.bg-outline{
    background-color: white;
    color: #70CB71;
    border-color: #70CB71;
}
.bg-outline:hover{
    background-color: #70CB71;
    color: white !important;
    border-color: #70CB71 !important;
}
.bg-secondary:hover {
    background-color: darkgreen !important;
}
.ant-btn-dangerous:hover {
    background-color: darkred !important;
}
/*.anticon {*/
/*    color: white;*/
/*    margin-bottom: 10px;*/
/*}*/
a:hover {
    background-color: rgba(211, 211, 211, 0.219);
    color: #70CB71;
    border-radius: 5px;
}
.imgCenter {
    display: block;
    margin: auto;
}
.imgWhite {
    filter: brightness(0) invert(1);
}
.sidemenu {
    height: 80vh;
}
.dropdownMenu {
    background-color: white;
}
.dropdownMenu:hover, .dropdownMenu .anticon:hover {
    background-color: white;
    color: #70CB71 !important;
    border-color: #70CB71 !important;
}
.dropdownMenu .anticon {
    color: black;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
/*}*/
